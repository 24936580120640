import { Cradle } from 'services/serviceContainer.types';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import i18next, { i18n as II18n } from 'i18next';
import { BUILD_API_ENDPOINT, IS_DEV_ENV } from 'constants/configs';
import { II18nInitializer } from 'services/i18n/I18nInitializer.types';

export class I18nInitializer implements II18nInitializer {
  private logger: Cradle['logger'];

  public constructor({ logger }: Pick<Cradle, 'logger'>) {
    this.logger = logger;
  }

  public initI18n(): II18n {
    const i18n = i18next.createInstance();

    // Expose i18n instance for debugging purpose
    if (typeof globalThis !== 'undefined') {
      // @ts-ignore
      globalThis.i18n = i18n;
    }

    // Reload resources when language changed
    i18n.on('languageChanged', (lang) => {
      i18n.reloadResources([lang]);
    });

    i18n
      .use(HttpApi)
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        fallbackLng: 'en',
        debug: IS_DEV_ENV,
        interpolation: {
          escapeValue: false,
        },
        ns: ['common'],
        defaultNS: 'common',
        nsSeparator: '|',
        keySeparator: false,
        backend: {
          loadPath: `${BUILD_API_ENDPOINT}/static/translations/{{lng}}/{{ns}}.json`,
          crossDomain: true,
        },
        detection: {
          order: ['localStorage'],
          caches: ['localStorage'],
          lookupLocalStorage: 'i18nextLang',
        },
      });

    return i18n;
  }
}
