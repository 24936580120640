import React from 'react';
import { Box, useTheme } from '@mui/material';
import { GlobalHeader } from 'odl-components';
// import { useTranslation } from 'react-i18next';

import { useScreenWidthMatch } from 'hooks/useScreenWidthMatch';
import Logo from 'assets/images/OBJ-Redact-H-White.svg';
import UserNavItem from './UserNavItem';
// import Logo from 'assets/images/OBJ-Redact-H-White.svg';

const AppHeader: React.FC = () => {
  const theme = useTheme();
  // const { t } = useTranslation();
  const screenWidthMatch = useScreenWidthMatch();

  // title does not like being translated
  // t('Redact Portal')
  
  return (
    <GlobalHeader
      brandColour={theme.palette.objective.brandColors.redact.primary}
      logo={<img src={Logo} alt="redact-logo" height="24" />}
      title="Redact Portal"
      homeLink="/"
      a11yId="app-header"
      endNode={
        <Box
          component="nav"
          position="absolute"
          top={0}
          right={0}
          display="flex"
          alignItems="center"
          height={theme.spacing(13)}
          paddingTop={theme.spacing(1)}
          paddingRight={theme.spacing(6)}
        >
          <UserNavItem compact={!screenWidthMatch.sm} />
        </Box>
      }
    />
  );
};
export default AppHeader;
