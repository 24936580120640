import axios, { AxiosInstance } from 'axios';
import { IIPLookupService, ILocationData } from './IPLookupService.types';

class IPLookupService implements IIPLookupService {
  private instance: AxiosInstance = axios.create({
    baseURL: 'https://ipapi.co/',
  });

  /**
   * Get the customer information for the current token
   * @returns A promise that resolves to an array of `ICustomer` entries
   */
  public async getLocation(): Promise<ILocationData> {
    const response = await this.instance.get<ILocationData>('json');
    return response.data;
  }
}

export default IPLookupService;
