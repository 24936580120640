import { createTheme } from '@mui/material/styles';
import { objectivePalette } from 'styles/objectivePalette';
// import ObjectiveWOFF from 'assets/fonts/Objective.woff';

// prettier-ignore
const standardFontFamily = [
  "Roboto",
  "-apple-system",
  "BlinkMacSystemFont",
  "Arial",
  "Helvetica",
  "sans-serif",
].join(",");

export const defaultTheme = createTheme({
  // Color palette
  palette: {
    objective: objectivePalette,
    primary: {
      main: objectivePalette.system.primary,
    },
    error: {
      main: objectivePalette.red.main,
    },
    text: {
      primary: objectivePalette.dark.night,
      secondary: objectivePalette.dark.neutral,
    },
    background: {
      default: objectivePalette.light.mid,
      paper: objectivePalette.light.white,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768, // iPad portrait
      md: 1024, // iPad landscape
      lg: 1280, // laptop (mackbook)
      xl: 1440, // desktop
      // xxl: 1600, // desktop
      // xxxl: 1920, // desktop (design)
    },
  },
  // Spacing - based on ODL factor4
  spacing: (factor: number): string | number => `${factor * 4}px`,
  // Typography
  typography: {
    // Font family - based on ODL
    fontFamily: standardFontFamily,
    htmlFontSize: 18,
    error: {
      color: objectivePalette.red.main,
      fontSize: '1rem',
    },
    label: {
      fontWeight: '500',
    },
    italic: {
      fontStyle: 'italic',
    },
    link: {
      color: objectivePalette.blue.main,
    },
  },
});