import React, { Suspense } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';

import LoginLayout from './LoginLayout';

const AuthController: React.FC = () => {
  return (
    <Grid
      container
      item
      direction="column"
      alignContent="center"
      justifyContent="center"
      id="scrollable-grid"
      minHeight="100vh"
    >
      <Grid container item justifyContent="center">
        <Suspense fallback={<CircularProgress size={100} thickness={4} disableShrink />}>
          <LoginLayout>
            <Outlet />
          </LoginLayout>
        </Suspense>
      </Grid>
    </Grid>
  );
};

export default AuthController;
