// Proxy access to process.env to provide meaningful defaults

// Backend
export const BUILD_API_ENDPOINT: string = process.env.REACT_APP_BUILD_API_ENDPOINT || '';
export const USE_MOCK_BACKEND = process.env.REACT_APP_USE_MOCK_BACKEND === 'true';

// Env
export const NODE_ENV: string = process.env.NODE_ENV || 'development';
export const IS_DEV_ENV: boolean = NODE_ENV === 'development';
export const IS_TEST_ENV: boolean = NODE_ENV === 'test';
export const IS_PRODUCTION_ENV: boolean = NODE_ENV === 'production';

// AWS
export const AWS_COGNITO_REGION = process.env.REACT_APP_AWS_COGNITO_REGION || '';
export const AWS_USER_POOLS_ID = process.env.REACT_APP_AWS_USER_POOLS_ID || '';
export const AWS_USER_POOLS_WEB_CLIENT_ID = process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID || '';
export const AWS_COGNITO_MOCK_USER_ACCOUNT = process.env.REACT_APP_AWS_COGNITO_MOCK_USER_ACCOUNT || '';
export const AWS_COGNITO_MOCK_USER_PASSWORD = process.env.REACT_APP_AWS_COGNITO_MOCK_USER_PASSWORD || '';
export const AWS_COGNITO_USE_MOCK = process.env.REACT_APP_AWS_COGNITO_USE_MOCK === 'true';

// Timings
export const DATA_REFRESH_DEFAULT_INTERVAL = 60000;
export const DATA_REFRESH_POLLING_INTERVAL = 5000;

// Defaults
export const DEFAULT_COUNTRY_CODE = 'AU'; // can use 'US' when that is an option in the CountrySelect
