import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

import { Box, Grid, styled } from '@mui/material';
import { Auth, Hub } from 'aws-amplify';
import { useStoreDispatch } from 'store/hooks';
import { serviceContainer } from 'services/serviceContainer';
import {
  setAuthenticated,
  setUserUniqueId,
  setUserFirstName,
  setUserLastName,
  setUserEmail,
  setUserPermissions,
  setEmailVerified,
  setFederatedLogin,
} from 'store/app-state/authentication/authentication';

import useWindowDimensions from 'hooks/useWindowDimensions';
import { useScreenWidthMatch } from 'hooks/useScreenWidthMatch';
import InformationPage from './InformationPage';
import AuthController from './AuthController';

const AuthSegments = ['signin', 'new-account', 'reset'];

const LoginPage: React.FC = () => {
  const { height } = useWindowDimensions();
  const screenWidthMatch = useScreenWidthMatch();
  const [query] = useSearchParams();

  const dispatch = useStoreDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // get subscription Id from upgrade link
  const upgradeId = query.get('id');
  const [originalPage, setOriginalPage] = useState<string>('');
  
  useEffect(() => {
    let redirectPage: string = '';

    function checkSession() {
      Auth.currentSession()
        .then((sess) => {
          if (sess.isValid()) {
            const token = sess.getIdToken();
            const payload = token.decodePayload();
            dispatch(setUserUniqueId(payload.sub));
            dispatch(setUserFirstName(payload.given_name));
            dispatch(setUserLastName(payload.family_name));
            dispatch(setUserEmail(payload.email));
            dispatch(setEmailVerified(payload.email_verified));
            dispatch(setFederatedLogin(payload.identities && payload.identities.length > 0));
            dispatch(setAuthenticated(true));
            serviceContainer.cradle.licenseServer
              .getUserState()
              .then((details) => {
                dispatch(setUserPermissions(details.data.userCan));
                navigate('/' + redirectPage);
              })
              .catch((error) => {
                console.error(error);
              });
          } else {
            if (redirectPage !== '') {
              navigate('/auth/signin?path=' + redirectPage);
            } else {
              navigate('/auth/signin');
            }
          }
        })
        .catch(() => {
          if (redirectPage !== '') {
            navigate('/auth/signin?path=' + redirectPage);
          } else {
            navigate('/auth/signin');
          }
        });
    }

    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          // the customOAuthState event happens after the signIn event and contains the page to redirect to
          // so use a timeout in case there isn't a custom state or it doesn't arrive
          setTimeout(() => checkSession(), 100);
          break;
        case 'cognitoHostedUI':
          // the cognitoHostedUI event occurs after the signIn event
          break;
        case 'customOAuthState':
          redirectPage = data;
          break;
        case 'signOut':
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          break;
      }
    });
    if (location.pathname !== '/auth/signin') {
      const page = location.pathname.split('/').pop();
      if (page && !AuthSegments.includes(page)) {
        redirectPage = page;
        if (page === 'upgrade' && upgradeId) {
          redirectPage += `?id=${upgradeId}`;
        }
        setOriginalPage(page);
      }
      checkSession();
    }
    return unsubscribe;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box height={height - 1}>
      <Grid container direction="column" alignContent="center">
        <Grid container direction="row">
          {screenWidthMatch.lg && (
            <InfoGrid container item xs alignContent="center" justifyContent="center">
              <InformationPage segment={originalPage} />
            </InfoGrid>
          )}
          <LoginGrid container item xs alignContent="center" justifyContent="center">
            <AuthController />
          </LoginGrid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginPage;

const InfoGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.objective.light.white,
}));

const LoginGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.objective.brandColors.redact.web,
}));
