import React from 'react';
import { Grid } from '@mui/material';

import redactLogo from 'assets/images/OBJ-Redact-H-White.svg';
import redactMarkWhite from 'assets/images/OBJ-Redact-Mark-White.svg';
import Spacer from 'components/Spacer/Spacer';

type Props = {
  children?: React.ReactNode;
};

const LoginLayout: React.FC<Props> = ({ children }) => {
  return (
    <React.Fragment>
      <Grid container item direction="column">
        <Spacer y={4} />
          {window.screen.width < 370 ? (
            <img src={redactMarkWhite} alt="Objective Redact logo" height="35px" />
          ) : (
            <img src={redactLogo} alt="Objective Redact logo" height="35px" />
          )}
        <Spacer y={4} />
        <Grid container item justifyContent="center">
          {children}
        </Grid>

        <Spacer y={4} />
      </Grid>
    </React.Fragment>
  );
};

export default LoginLayout;
