import { initReactI18next } from 'react-i18next';
import i18next, { i18n as II18n } from 'i18next';
import { II18nInitializer } from 'services/i18n/I18nInitializer.types';

export class I18nInitializerMock implements II18nInitializer {
  public initI18n(): II18n {
    const i18n = i18next.createInstance();

    i18n.use(initReactI18next).init({
      lng: 'cimode',
      fallbackLng: 'cimode',
      debug: false,
      interpolation: {
        escapeValue: false,
      },
      ns: ['common', 'validation'],
      defaultNS: 'common',
      nsSeparator: '|',
      keySeparator: false,
      resources: {
        cimode: {
          common: {},
          validation: {},
        },
      },
      react: {
        useSuspense: false,
      },
    });

    return i18n;
  }
}
