import React from 'react';

import { GlobalNavigation, GlobalNavigationItem } from 'odl-components';
import { Box, styled, useTheme } from '@mui/material';

import { useMinimizable, useNavItems } from 'components/AppSideNavigation/AppSideNavigation.functions';

type Props = {};

const AppSideNavigation: React.FC<Props> = (props) => {
  const theme = useTheme();
  const navItems = useNavItems();
  const { isMinimised, toggleMinimised } = useMinimizable();

  return (
    <NavBox component="nav" aria-label="Side navigation" data-testid="AppSideNavigation">
      <GlobalNavigation
        a11yId="global-side-navigation"
        ariaLabelCollapse="global-side-navigation-collapse"
        ariaLabelExpand="global-side-navigation-expand"
        ariaLabelNav="global-side-navigation"
        isMinimised={isMinimised}
        onCollapseNavHandler={toggleMinimised}
        onExpandNavHandler={toggleMinimised}
      >
        {navItems.length &&
          navItems.map((navItem, index) => (
            <StyledGlobalNavigationItem
              key={navItem.id}
              a11yId={navItem.id}
              name={navItem.title}
              type={navItem.icon}
              selected={navItem.selected}
              brandColour={theme.palette.objective.brandColors.redact.primary}
              onClickHandler={navItem.onClick}
            />
          ))}
      </GlobalNavigation>
    </NavBox>
  );
};

export default AppSideNavigation;

const NavBox = styled(Box)(({ theme }) => ({
  '& a': {
    width: '100%',
  },
}));

const StyledGlobalNavigationItem = styled(GlobalNavigationItem)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  '& .odlGlobalNavigationItem__icon': {
    justifyContent: 'center',
  },
  '& .icon.odlGlobalNavigationItem__icon': {
    marginLeft: '2px',
    marginRight: '14px',
  },
}));
