import React from 'react';
import { Outlet } from 'react-router-dom';

import { Box, styled, useTheme } from '@mui/material';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AppHeader from 'components/AppHeader/AppHeader';
import AppSideNavigation from 'components/AppSideNavigation/AppSideNavigation';

const MainLayout = () => {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" overflow="hidden" minHeight="100vh" maxHeight="100vh">
      <Box flexGrow={0} flexShrink={0}>
        <AppHeader />
      </Box>
      <Box flexGrow={1} display="flex" overflow="hidden">
        <Box flexGrow={0} flexShrink={0} bgcolor={theme.palette.objective.dark.night}>
          <AppSideNavigation />
        </Box>
        <OverflowBox component="main" minWidth={320} flexGrow={1}>
          <Outlet />
        </OverflowBox>
      </Box>
      <ToastContainer
        position="bottom-left"
        transition={Slide}
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
    </Box>
  );
};

export default MainLayout;

const OverflowBox = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  paddingBottom: theme.spacing(8),
}));
