import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Backdrop, Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStoreSelector } from 'store/hooks';
import { selectAuthenticationState } from 'store/app-state/authentication/authentication';

import Spacer from 'components/Spacer/Spacer';
import MainLayout from 'components/MainLayout/MainLayout';
import LoginPage from 'components/AuthUI/Login';

const HomePage = React.lazy(() => import('pages/home/HomePage'));
const ProfilePage = React.lazy(() => import('pages/profile/ProfilePage'));
const UserManagementPage = React.lazy(() => import('pages/user-management/UserManagementPage'));
const SupportPage = React.lazy(() => import('pages/support/SupportPage'));
const TrialPage = React.lazy(() => import('pages/trials/TrialPage'));
const DashboardPage = React.lazy(() => import('pages/dashboard/DashboardPage'));
const PurchasePage = React.lazy(() => import('pages/purchase/PurchasePage'));
const NoMatchPage = React.lazy(() => import('pages/no-match/NoMatchPage'));
const LoginScreen = React.lazy(() => import('components/AuthUI/LoginScreen'));
const NewAccount = React.lazy(() => import('components/AuthUI/NewAccount'));
const ResetPassword = React.lazy(() => import('components/AuthUI/ResetPassword'));
const SignOutPage = React.lazy(() => import('pages/sign-out/SignOutPage'));

const RouteController: React.FC = () => {
  const { t } = useTranslation();
  const { authenticated, userPermissions } = useStoreSelector(selectAuthenticationState);

  return (
    <Router>
      <Suspense
        fallback={
          <Backdrop open={true}>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <CircularProgress size={100} thickness={4} disableShrink />
              <Spacer y={4} />
              {t('Loading...')}
            </Box>
          </Backdrop>
        }
      >
        <Routes>
          <Route path="/signin" element={<LoginPage />} />
          <Route path="/signout" element={<SignOutPage />} />
          {!authenticated && (
            <Route path="/auth" element={<LoginPage />}>
              <Route index element={<LoginScreen />} />
              <Route path="signin" element={<LoginScreen />} />
              <Route path="new-account" element={<NewAccount />} />
              <Route path="reset" element={<ResetPassword />} />
            </Route>
          )}
          {!authenticated && <Route path="*" element={<LoginPage />} />}
          {authenticated && (
            <Route path="/" element={<MainLayout />}>
              <Route index element={<HomePage />} />
              <Route path="upgrade" element={<HomePage />} />
              <Route path="profile" element={<ProfilePage />} />
              <Route path="users" element={<UserManagementPage />} />
              {userPermissions.includes('ManageCustomers') && <Route path="support" element={<SupportPage />} />}
              {userPermissions.includes('ManageCustomers') && <Route path="dashboard" element={<DashboardPage />} />}
              {userPermissions.includes('ManageCustomers') && <Route path="trial" element={<TrialPage />} />}
              <Route path="purchase" element={<PurchasePage />} />
              <Route path="*" element={<NoMatchPage />} />
            </Route>
          )}
        </Routes>
      </Suspense>
    </Router>
  );
};

export default RouteController;
