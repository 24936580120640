import React, { useState } from 'react';
import { Box, Menu, MenuItem, styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Spacer from 'components/Spacer/Spacer';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useStoreSelector } from 'store/hooks';
import { selectAuthenticationState } from 'store/app-state/authentication/authentication';
import AboutDialog from 'components/AboutDialog/AboutDialog';

type Props = {
  compact?: boolean;
};

const UserNavItem: React.FC<Props> = ({ compact = false }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { userFirstName, userLastName } = useStoreSelector(selectAuthenticationState);
  const [showAboutDialog, setShowAboutDialog] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClickOpenMenu = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClickProfile = React.useCallback(() => {
    setAnchorEl(null);
    navigate('/profile');
  }, [navigate]);

  const handleClickAbout = () => {
    setAnchorEl(null);
    setShowAboutDialog(true);
  };

  const handleClickLogout = React.useCallback(() => {
    setAnchorEl(null);
    Auth.signOut().then(() => {
      navigate('/signout');
    });
  }, [navigate]);

  return (
    <Box data-testid="UserNavItem">
      <StyledBox
        height={24}
        display="flex"
        alignItems="center"
        role="button"
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleClickOpenMenu}
      >
        <Box
          fontWeight={700}
          fontSize={14}
          width={18}
          height={18}
          color={theme.palette.objective.light.white}
          display="flex"
          justifyContent="center"
          alignItems="center"
          className="icon icon-account-circle"
        />
        {!compact && (
          <React.Fragment>
            <Spacer x={4} />
            <Box fontWeight={500} fontSize={14} color={theme.palette.objective.light.white}>
              {userFirstName.toUpperCase()} {userLastName.toUpperCase()}
              <Box component="span" className="icon icon-menu-down" />
            </Box>
          </React.Fragment>
        )}
      </StyledBox>
      <StyledMenu
        id="user-menu"
        data-testid="user-menu"
        keepMounted={true}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={
          {
            vertical: 'bottom',
            horizontal: 'right',
          }
        }
      >
        <StyledMenuItem onClick={handleClickProfile}>
          <Box className="icon icon-account-card-details" paddingRight={theme.spacing(2)} />
          <Box>{t('Profile').toUpperCase()}</Box>
        </StyledMenuItem>
        <StyledMenuItem onClick={handleClickAbout}>
          <Box className="icon icon-information-outline" paddingRight={theme.spacing(2)} />
          <Box>{t('About').toUpperCase()}</Box>
        </StyledMenuItem>
        <StyledMenuItem onClick={handleClickLogout}>
          <Box className="icon icon-logout-variant" paddingRight={theme.spacing(2)} />
          <Box>{t('Logout').toUpperCase()}</Box>
        </StyledMenuItem>
      </StyledMenu>
      <AboutDialog open={showAboutDialog} onClose={() => setShowAboutDialog(false)} />
    </Box>
  );
};

export default UserNavItem;

const StyledBox = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiListItem-root': {
    minWidth: '192px',
    lineHeight: '36px',
    paddingTop: 0,
    paddingBottom: 0,
  },
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.objective.dark.night,
    color: theme.palette.objective.light.white,
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  minWidth: '192px',
  '&:hover': {
    backgroundColor: theme.palette.objective.dark.fiordland,
  },
}));
