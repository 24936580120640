import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from 'components/App/App';
// import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import { serviceContainer } from 'services/serviceContainer';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/400.css'; // Regular
import '@fontsource/roboto/500.css'; // Medium
import '@fontsource/roboto/700.css'; // Bold
import 'styles/objective-icons.css'; // Objective Icons
import 'styles/odl-phone-input.css'; // ODL phone input

// Initialize global i18n instance
serviceContainer.cradle.i18nInitializer.initI18n();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
