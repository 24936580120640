import axios, { AxiosHeaders, AxiosInstance, CancelTokenSource } from 'axios';
import { Auth } from '@aws-amplify/auth';
import * as LicenceServerTypes from 'services/license-server/LicenseServerService.types';

class LicenseServerService implements LicenceServerTypes.ILicenseServerService {
  private instance: AxiosInstance;
  private authInstance: AxiosInstance;
  private dataInstance: AxiosInstance;
  private _searchCancel?: CancelTokenSource;

  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_LICENCE_SERVER_API_BASE_URL,
    });
    this.instance.interceptors.request.use(
      (config) => {
        return new Promise((resolve, reject) => {
          Auth.currentSession()
            .then((session) => {
              const authToken = 'Bearer ' + session.getIdToken().getJwtToken();
              if (config.headers) {
                config.headers.Authorization = authToken;
                } else {
                  (config.headers as AxiosHeaders).set('Authorization', authToken) 
              }
              resolve(config);
            })
            .catch((error) => {
              console.error('License Server Interceptor session error', error);
              reject(error);
            });
        });
      },
      (error) => {
        console.error('License Server Interceptor rejected', error);
      }
    );

    this.authInstance = axios.create({
      baseURL: process.env.REACT_APP_LICENCE_SERVER_API_AUTH_URL,
    });

    this.dataInstance = axios.create({
      baseURL: process.env.REACT_APP_LICENCE_SERVER_API_DATA_URL,
    });
    this.dataInstance.interceptors.request.use(
      (config) => {
        return new Promise((resolve) => {
          const authToken = 'Basic TFNEIERhc2hib2FyZDowMDcgU2hvdyBNZSBUaGUgRGF0YSE=';
          if (config.headers) {
            config.headers.Authorization = authToken;
            } else {
              (config.headers as AxiosHeaders).set('Authorization', authToken) 
          }
          resolve(config);
        });
      },
      (error) => {
        console.error('License Server data Interceptor rejected', error);
      }
    );
  }

  /**
   * Check if the given error is a cancel from an Axios request
   * @param error The error to be checked
   * @returns TRUE if this is an Axios cancel, FALSE otherwise
   */
  public isCancel(error: any): boolean {
    return axios.isCancel(error);
  }

  /**
   * Send request to make administrator
   * @param subscriptionId the Id of the subscription
   * @param email The email address of nominated administrator
   * @returns An error message or no data if successful
   */
  public async addAdmin(subscriptionId: string, email: string): Promise<any> {
    const response = await this.instance.post<any>(`v1/subscription/${subscriptionId}/admin`, {
      email: email,
    });
    return response.status === 201 ? 'Successful' : 'response.data';
  }

  /**
   * Send request to cancel nominated subscription
   * @param subscriptionId the Id of the subscription
   * @returns An error message or no data if successful
   */
  public async cancelSubscription(subscriptionId: string): Promise<any> {
    const response = await this.instance.delete<any>(`v1/subscription/${subscriptionId}`);
    return response.status === 201 ? 'Successful' : 'response.data';
  }

  /**
   * Gets list of current available redact plans
   * @returns A promise that resolves a list billing formation
   */
  public async getBillingInfo(subscriptionId: string): Promise<any> {
    const response = await this.instance.get<any>(`v1/subscription/${subscriptionId}/billinginfo`);
    return response.data;
  }

  /**
   * Check if the given domain is federated in the Objective AWS Cognito system
   * @param domain The domain to be checked
   * @returns A promise resolving to TRUE if federated, FALSE otherwise
   */
  public async getDomainFederated(domain: string): Promise<boolean> {
    const response = await this.authInstance.get<LicenceServerTypes.IFederatedResponse>(
      `v1/cognito/${domain}/federated`
    );
    return response.data.IsFederated;
  }
  
  /**
   * Gets Chargebee url for subscription payments
   * @returns A promise that resolves payment information
   */
  public async getPaymentInfo(subscriptionId: string): Promise<any> {
    const response = await this.instance.get<any>(`v1/subscription/${subscriptionId}/paymentinfo`);
    return response.data;
  }

  /**
   * Gets list of current available redact plans
   * @returns A promise that resolves a list of `IPlan` entries
   */
  public async getPlans(): Promise<LicenceServerTypes.IPlans> {
    const response = await this.instance.get<any>('v1/plans');
    return response.data;
  }

  /**
   * Get list of all subscriptions according to search criteria
   * @param searchValue string value to filter response list
   * @param offset optional page offset for pagination
   * @param limit optional limit to the number of records returned
   * @returns list of subscriptions that resolves a list of `ISubscription`
   */
  public async getSubscriptions(searchValue: string, offset?: number, limit?: number): Promise<any> {
    if (this._searchCancel) {
      this._searchCancel.cancel();
    }
    this._searchCancel = axios.CancelToken.source();
    const config = {
      cancelToken: this._searchCancel.token,
      params: {
        Search: searchValue,
        Offset: offset,
        Limit: limit,
      },
    };
    const response = await this.instance.get<any>('v1/Subscription', config);
    return response.data;
  }

  /**
   * Get list of all subscriptions for logged in user
   * @returns list of subscriptions that resolves a list of `ISubscription`
   */
  public async getUserSubscriptions(): Promise<LicenceServerTypes.ISubscriptions> {
    const response = await this.instance.get<any>('v1/Subscription');
    return response.data;
  }

  /**
   * Gets list of permissions for the logged in user
   * @returns A promise that resolves a list of permissions
   */
  public async getUserState(): Promise<any> {
    const response = await this.instance.get<any>('v1/userstate');
    return response;
  }

  /**
   * Gets list of all work logs for subscription
   * @param subscriptionId Id of nominated subscription
   * @returns A promise that resolves a list of worklogs
   */
  public async getWorkLogs(subscriptionId: string): Promise<any> {
    const response = await this.instance.get<any>(`v1/subscription/${subscriptionId}/workLog`);
    return response;
  }

  /**
   * Post purchase request
   * @param purchaseData data of the purchase of type IPurchase
   * @returns An error message or no data if successful
   */
  public async purchaseSubscription(purchaseData: LicenceServerTypes.IPurchase): Promise<any> {
    const response = await this.instance.post<any>('v1/purchaseRequest', purchaseData);
    return response;
  }

  /**
   * Remove selected administrator from subscription
   * @param subscriptionId the Id of the subscription
   * @param email The email address of administrator to be removed
   * @returns An error message or no data if successful
   */
  public async removeAdmin(subscriptionId: string, email: string): Promise<boolean> {
    const response = await this.instance.delete<any>(`v1/subscription/${subscriptionId}/admin`, {
      data: { email: email },
    });
    return response.status === 201;
  }

  /**
   * Remove selected user from subscription
   * @param subscriptionId the Id of the subscription
   * @param email The email address of user to be removed
   * @param reason The users reason for removal
   * @returns An error message or no data if successful
   */
  public async removeUser(subscriptionId: string, userId: string, reason: string): Promise<any> {
    const data = { reason: reason };
    const response = await this.instance.post<any>(`v1/subscription/${subscriptionId}/user/${userId}/block`, data);
    return response.status === 201 ? 'Successful' : 'response.data';
  }

    /**
   * Requests data for the dashboard graphs
   * @param request The information about what data is being requested
   * @returns The requested data
   */
    public async requestDashboardData(
      request: LicenceServerTypes.IDataRequest
    ): Promise<LicenceServerTypes.IDataResponse> {
      const response = await this.dataInstance.post<any>('v1/datarequest', request);
      return response.data;
    }

  /**
   * Reset selected user access on subscription
   * @param subscriptionId the Id of the subscription
   * @param email The email address of user to be removed
   * @returns An error message or no data if successful
   */
  public async resetUser(subscriptionId: string, userId: string): Promise<boolean> {
    const response = await this.instance.delete<any>(`v1/subscription/${subscriptionId}/user/${userId}/unblock`);
    return response.status === 204;
  }

  /**
   * Reset selected user access on subscription
   * @param subscriptionId the Id of the subscription
   * @returns true if successful
   */
  public async resetAllUsers(subscriptionId: string): Promise<boolean> {
    const response = await this.instance.delete<any>(`v1/subscription/${subscriptionId}/users`);
    return response.status === 204;
  }

  /**
   * Post to update a subscription billing information
   * @param subscriptionId The Id of the subscription to update
   * @param billingData updated billing data of subscription
   * @returns An error message or no data if successful
   */
  public async updateBillingInfo(subscriptionId: string, billingData: LicenceServerTypes.IBillingInfo): Promise<any> {
    const response = await this.instance.post<any>(`v1/subscription/${subscriptionId}/billingInfo`, billingData);
    return response;
  }

  /**
   * Post to update a subscription (response without a chargebee redirect)
   * @param subscriptionId The Id of the subscription to update
   * @param maxUserCount data of the purchase of type IPurchase
   * @returns An error message or no data if successful
   */
  public async updateSubscription(subscriptionId: string, maxUserCount: number): Promise<any> {
    const data = { MaxUserCount: maxUserCount };
    const response = await this.instance.post<any>(`v1/subscription/${subscriptionId}`, data);
    return response;
  }

  /**
   * Post to upgrade a subscription (also used for upgrading trial subscription)
   * @param subscriptionId The Id of the subscription to upgrade
   * @param quantity of users for the upgraded subscription
   * @returns An error message or no data if successful
   */
  public async upgradeSubscription(subscriptionId: string, quantity: number): Promise<any> {
    const data = { SubscriptionId: subscriptionId, PlanQuantity: quantity };
    const response = await this.instance.post<any>('v1/upgrade', data);
    return response;
  }
}

export default LicenseServerService;
