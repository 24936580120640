import { Grid, Typography } from '@mui/material';
import { Button } from 'odl-components';
import { useTranslation } from 'react-i18next';

import useWindowDimensions from 'hooks/useWindowDimensions';
import teamImg from 'assets/images/team.png';
import Spacer from 'components/Spacer/Spacer';
import React from 'react';

interface Props {
  segment: string;
}

const InformationPage: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const onLearnClick = () => {
    window.open('https://www.objective.com/resources/all?product=objective-redact', '_blank', 'noopener,noreferrer');
  };
  return (
    <Grid container direction="column" height="100%" alignContent="center" justifyContent="center">
      <Grid container direction="column" width="80%">
        {props.segment === 'trial' || props.segment === 'purchase' ? (
          <React.Fragment>
            {width > 2000 ? (
              <Grid item>
                <Typography variant="h5" color="black" align="center">
                  {t('To purchase Objective Redact you will need an Objective ID account.')}
                </Typography>
                <Spacer y={3} />
                <Typography variant="h5" color="black" align="center">
                  {t("Please create a new account if you don't have one already then sign in to continue.")}
                </Typography>
              </Grid>
            ) : (
              <Grid item>
                <Typography variant="h5" color="black" align="center">
                  {t(
                    "To purchase Objective Redact you will need an Objective ID account. Please create a new account if you don't have one already then sign in to continue."
                  )}
                </Typography>
              </Grid>
            )}
          </React.Fragment>
        ) : (
          <Grid item>
            <Typography variant="h5" color="black" align="center">
              {t('Redaction software for security conscious organizations')}
            </Typography>
          </Grid>
        )}

        <Spacer y={8} />
        <Grid container item justifyContent="center">
          <img src={teamImg} alt="redact-team-img" width="50%" />
        </Grid>
        <Spacer y={8} />
        <Grid item>
          <Typography color="black" align="center">
            {t('Protect yourself and your organization from accidental disclosure.')}
          </Typography>
        </Grid>
        <Spacer y={4} />
        <Grid container item justifyContent="center">
          <Button variant="contained" size="large" onClick={onLearnClick}>
            {t('Learn More')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InformationPage;
