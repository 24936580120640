import React from "react";

import { CssBaseline, ThemeProvider } from "@mui/material";

import "./App.css";
import { defaultTheme } from "styles/defaultTheme";
import RouteController from "components/RouteController/RouteController";
import { Amplify } from "aws-amplify";

const config = {
  Auth: {
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_AWS_COGNITO_OAUTH_DOMAIN,
      scope: ['openid', 'profile', 'email'],
      redirectSignIn: `${process.env.REACT_APP_AWS_COGNITO_OAUTH_BASE_URL}signin`,
      redirectSignOut: `${process.env.REACT_APP_AWS_COGNITO_OAUTH_BASE_URL}signout`,
      responseType: 'code',
    },
  },
};

function App() {
  Amplify.configure(config);
  
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <RouteController />
    </ThemeProvider>
  );
}

export default App;
