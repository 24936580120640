import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Link,
  DialogTitle,
  Typography,
  styled,
} from '@mui/material';

import Spacer from 'components/Spacer/Spacer';
import useWindowDimensions from 'hooks/useWindowDimensions';

interface Props {
  onClose: () => void;
  open: boolean;
}

const MinScreenWidth = 800;

const AboutDialog: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const handleClose = () => {
    props.onClose();
  };

  return (
    <StyledDialog maxWidth="sm" onClose={handleClose} aria-labelledby="about-dialog-title" open={props.open}>
      <DialogTitle>
        <Grid container direction="row">
          <Grid container item xs alignContent="center">
            <Typography variant="h5">{t('About Objective Redact')}</Typography>
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={props.onClose}>
              <Box className="icon icon-window-close" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction="row">
          {width > MinScreenWidth && (
            <Grid container item xs={3} justifyContent="center" alignContent="center">
              <Box className="icon icon-objective-logo-redact" />
            </Grid>
          )}
          <Grid container item xs direction="column">
            <Spacer y={6} />
            <Grid container item alignContent="flex-start">
              <Grid item>
                <Typography>Copyright © 2023 Objective Corporation Limited</Typography>
              </Grid>
            </Grid>
            <Spacer y={12} />
            <Grid item>
              <Typography>{t('Contact Details')}</Typography>
            </Grid>
            <Spacer y={5} />
            <Grid container item xs direction="row">
              <Grid item>
                <Grid item>
                  <Typography>{t('Web')}:</Typography>
                </Grid>
                <Spacer y={4} />
                <Grid item>
                  <Typography>{t('Email')}:</Typography>
                </Grid>
              </Grid>
              <Spacer x={2} />
              <Grid item xs>
                <Grid item>
                  <Link href="https://www.objective.com/" target="_blank" rel="noopener" underline="always">
                    www.objective.com
                  </Link>
                </Grid>
                <Spacer y={4} />
                <Grid item>
                  <Link href="mailto:redact.support@objective.com?subject=Redact portal enquiry" underline="always">
                    redact.support@objective.com
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Spacer y={12} />
            <Divider />
            <Spacer y={4} />
            <Grid item xs>
              <Typography align="left">
                PDF technology powered by PDFNet SDK copyright © PDFTron™ Systems Inc., 2001-2016, and distributed by
                Objective Corporation Limited under license. All rights reserved.
              </Typography>
            </Grid>
            <Spacer y={8} />
          </Grid>
          <Spacer x={2} />
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
};

export default AboutDialog;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.objective.light.white,
    '& .icon-window-close': {
      color: theme.palette.objective.dark.night,
    },
    '& .icon-objective-logo-redact': {
      color: theme.palette.objective.brandColors.redact.web,
      fontSize: '7em',
      padding: '50px 80px 50px 50px',
    },
  },
}));
