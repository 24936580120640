import React from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { NavItem } from 'components/AppSideNavigation/AppSideNavigation.types';
import { useStoreSelector } from 'store/hooks';
import { selectAuthenticationState } from 'store/app-state/authentication/authentication';

export const useNavItems = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { userPermissions } = useStoreSelector(selectAuthenticationState);

  const navItems: NavItem[] = [
    {
      id: 'nav-home',
      icon: 'home',
      title: t('home'),
      selected: pathname === '/',
      onClick: () => {
        navigate('/');
      },
    },
    {
      id: 'nav-profile',
      icon: 'account-card-details',
      title: t('profile'),
      selected: pathname === '/profile',
      onClick: () => {
        navigate('/profile');
      },
    },
    {
      id: 'nav-users',
      icon: 'account-multiple',
      title: t('users'),
      selected: pathname === '/users',
      onClick: () => {
        navigate('/users');
      },
    },
  ];

  if (userPermissions && userPermissions.includes('ManageCustomers')) {
    navItems.push({
      id: 'nav-dashboard',
      icon: 'view-dashboard',
      title: t('Dashboard'),
      selected: pathname === '/dashboard',
      onClick: () => {
        navigate('/dashboard');
      },
    });
    navItems.push({
      id: 'nav-support',
      icon: 'workgroup',
      title: t('Support'),
      selected: pathname === '/support',
      onClick: () => {
        navigate('/support');
      },
    });
  }

  return navItems;
};

export const useMinimizable = () => {
  const [isMinimised, setIsMinimised] = React.useState(true);
  const toggleMinimised = React.useCallback(() => {
    setIsMinimised((prevState) => !prevState);
  }, []);
  return {
    isMinimised,
    setIsMinimised,
    toggleMinimised,
  };
};
