import { useMediaQuery, useTheme } from '@mui/material';

export const useScreenWidthMatch = () => {
  const theme = useTheme();

  const xs = useMediaQuery(theme.breakpoints.up('xs')); // 0px
  const sm = useMediaQuery(theme.breakpoints.up('sm')); // 768px, iPad portrait
  const md = useMediaQuery(theme.breakpoints.up('md')); // 1024px, iPad landscape
  const lg = useMediaQuery(theme.breakpoints.up('lg')); // 1280px, laptop (mackbook)
  const xl = useMediaQuery(theme.breakpoints.up('xl')); // 1440px, desktop
  // const xxl = useMediaQuery(theme.breakpoints.up('xxl')); // 1600px, desktop
  // const xxxl = useMediaQuery(theme.breakpoints.up('xxxl')); // 1920px, desktop (design)

  return {
    xs,
    sm,
    md,
    lg,
    xl,
    // xxl,
    // xxxl,
  };
};
